import Head from "next/head";
import { useState } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useRouter } from "next/router";
import { Button, Flex, Form, Input, Modal, message, theme } from "antd";
import { destroyCookie } from "nookies";
import SigninOutLayout from "components/SigninOutLayout";
import WelcomeMessage from "components/WelcomeMessage";
import { ColorTextTertiary } from "components/01General/Typography";

import type { NextPage, GetServerSideProps } from "next";

import { firebaseAuth } from "(legacy)/infrastructure/providers/firebase-client";
import { loginServerSideProps } from "(legacy)/lib/server-side-props";
import { useDisclosure } from "(legacy)/hooks/useDisclosure";
import { SESSION_DATA_COOKIE_NAME } from "(legacy)/lib/constraints";

interface LoginProps {
  email: string;
  password: string;
}

const Index: NextPage = () => {
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);

  const { open, isOpen, close } = useDisclosure(false);

  const [sending, setSending] = useState(false);

  const [resetPasswordForm] = Form.useForm();

  const size = "large";
  const router = useRouter();

  const handleLogin = async (
    props: LoginProps
  ): Promise<boolean | undefined> => {
    setLoading(true);
    const idToken = await signInWithEmailAndPassword(
      firebaseAuth,
      props.email,
      props.password
    )
      .then((userCredential) => {
        return userCredential.user?.getIdToken();
      })
      .catch(() => {
        return null;
      });

    if (idToken) {
      try {
        const res = await fetch(`/api/session-login?idToken=${idToken}`);
        if (!res.ok) throw new Error(await res.text());

        destroyCookie(null, SESSION_DATA_COOKIE_NAME);
        return await router.push("/");
      } catch (e) {
        message.error("로그인에 실패하였습니다.");
      }
    } else {
      message.error("이메일, 비밀번호를 다시 한번 확인해주세요.");
    }
    // TODO: 비밀번호 여러번 실패하면 아예 계정 막힐수가 있는데 error별 핸들링 필요함.
    setLoading(false);
  };

  const handleSendResetPasswordEmail = ({ email }: { email: string }): void => {
    setSending(true);
    sendPasswordResetEmail(firebaseAuth, email)
      .then(() => {
        close();
        message.success("비밀번호 초기화 링크가 발송되었습니다.");
      })
      .catch(() => message.error("존재하지 않는 이메일 입니다."))
      .finally(() => setSending(false));
  };

  return (
    <SigninOutLayout>
      <style jsx>{`
        .wrapper {
          max-width: 300px;
          margin: 0 auto;
        }
        .message {
          text-align: center;
          margin-top: 16px;
          margin-bottom: 24px;
        }
      `}</style>
      <Head>
        <title>오르조 로그인</title>
      </Head>
      <div className="wrapper">
        <div className="message">
          <WelcomeMessage />
        </div>

        <Form
          name="basic"
          labelCol={{ span: 8 }}
          initialValues={{ remember: true }}
          onFinish={handleLogin}
        >
          <Form.Item
            name="email"
            style={{ marginBottom: 8 }}
            rules={[{ required: true, message: "이메일을 입력해주세요." }]}
          >
            <Input size={size} placeholder="이메일" />
          </Form.Item>

          <Form.Item
            name="password"
            style={{ marginBottom: 16 }}
            rules={[{ required: true, message: "비밀번호를 입력해주세요." }]}
          >
            <Input.Password size={size} placeholder="비밀번호" />
          </Form.Item>

          <Form.Item style={{ marginBottom: 12 }}>
            <Button
              size={size}
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
            >
              로그인
            </Button>
          </Form.Item>
        </Form>
        <Flex justify="center">
          <Button
            type="text"
            style={{ color: token.colorTextTertiary }}
            onClick={open}
          >
            비밀번호 찾기
          </Button>
        </Flex>
      </div>
      <Modal
        open={isOpen}
        onOk={(): void => resetPasswordForm.submit()}
        onCancel={close}
        closable={false}
        title="비밀번호 재설정"
        okText="이메일 전송하기"
        okButtonProps={{ loading: sending }}
        cancelText="취소"
      >
        <Flex vertical style={{ marginBottom: 24 }}>
          <ColorTextTertiary>
            오르조에 가입한 이메일을 입력 후 이메일 전송하기 버튼을 눌러주세요.
          </ColorTextTertiary>
          <ColorTextTertiary>
            이메일로 받은 링크를 통해 비밀번호를 재설정 할 수 있습니다.
          </ColorTextTertiary>
        </Flex>
        <Form form={resetPasswordForm} onFinish={handleSendResetPasswordEmail}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "이메일을 입력해주세요." }]}
          >
            <Input placeholder="이메일" />
          </Form.Item>
        </Form>
      </Modal>
    </SigninOutLayout>
  );
};

export default Index;

export const getServerSideProps: GetServerSideProps = loginServerSideProps;
