import { useState } from "react";

type UseDisclosure = (defaultValue: boolean) => {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export const useDisclosure: UseDisclosure = (defaultValue: boolean) => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const close = (): void => setIsOpen(false);

  const open = (): void => setIsOpen(true);

  const toggle = (): void => setIsOpen((prev) => !prev);

  return { open, close, toggle, isOpen };
};
