const ClassLogo = (): JSX.Element => (
  <h1 style={{ margin: 0 }}>
    <a
      href={"/"}
      rel="noreferrer"
      style={{ display: "flex", alignItems: "center" }}
    >
      <img src={"/tutor_logo.png"} width={120} alt="" />
    </a>
  </h1>
);

export default ClassLogo;
