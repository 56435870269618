import { theme, Typography } from "antd";

const { Text } = Typography;
const { useToken } = theme;

const WelcomeMessage = (): JSX.Element => {
  const { token } = useToken();
  return (
    <Text style={{ color: token.colorTextTertiary }}>
      오르조 클래스에 오신 것을 환영합니다.
    </Text>
  );
};

export default WelcomeMessage;
