import { Typography, theme } from "antd";
import getSize from "./getSize";

import type { FC, PropsWithChildren, HTMLAttributes } from "react";
import type { Size } from "./getSize";

const { useToken } = theme;
const { Text } = Typography;

interface Props {
  size?: Size;
}

export const ColorTextQuaternary: FC<
  PropsWithChildren & HTMLAttributes<HTMLSpanElement> & Props
> = ({ children, style, size }) => {
  const { token } = useToken();

  return (
    <Text
      style={{ ...getSize(size), ...style, color: token.colorTextQuaternary }}
    >
      {children}
    </Text>
  );
};
