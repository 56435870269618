import { Typography } from "antd";
import { useEffect, useState } from "react";

type TextProps = Parameters<typeof Typography.Text>[0];

type EditableConfig = Exclude<TextProps["editable"], boolean | undefined>;

type Props = Omit<TextProps, "editable"> & { editable: EditableConfig };

export const TextEditable = ({ editable, ...props }: Props): JSX.Element => {
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setEditing(true);
  }, []);

  return (
    <Typography.Text {...props} editable={editing ? editable : undefined} />
  );
};
