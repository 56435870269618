import { useEffect } from "react";
import { Row, Col } from "antd";
import ClassLogo from "./00shared-legacy/ClassLogo";

import type { FC, PropsWithChildren } from "react";
interface Props {
  visibleLogo?: boolean;
}

const SigninOutLayout: FC<PropsWithChildren<Props>> = ({
  children,
  visibleLogo = true,
}) => {
  useEffect(() => {
    import("../(legacy)/infrastructure/providers/ChannelService").then(
      (channel) => {
        channel.default.boot({
          pluginKey: "32f9de36-1e7c-4c66-ac3a-cff27b99e38c", //please fill with your plugin key
        });
      }
    );
  }, []);
  return (
    <div className={`root ${visibleLogo ? "" : "hidden-logo"}`}>
      <style jsx>{`
        .root {
          min-height: 100vh;
          padding-top: 60px;
          display: flex;
          flex: auto;
          flex-direction: column;
        }
        .hidden-logo {
          padding-top: 50px;
        }
        .logo {
          display: flex;
          justify-content: center;
        }
        .content {
          flex: auto;
          min-height: 0;
        }
      `}</style>

      {visibleLogo && (
        <div className="logo">
          <ClassLogo />
        </div>
      )}

      <div className="content">
        <Row>
          <Col span={22} offset={1}>
            {children}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SigninOutLayout;
