export type Size = "LG" | "SM"; // "large(LG)", "small(SM)", "extra large(XL)" : from antd for figma. / SM, XL 는 추후 추가.

type SizeStyle =
  | {
      fontSize: string;
      lineHeight: string;
    }
  | object;

export default function getSize(size: Size | undefined): SizeStyle {
  switch (size) {
    case "LG":
      return { fontSize: "16px", lineHeight: "24px" };
    case "SM":
      return { fontSize: "12px", lineHeight: "20px" };
    default:
      return {};
  }
}
